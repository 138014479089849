import './App.scss';
import Waitforit from './components/Waitforit';

function App() {
  return (

    <div className="App">
      <Waitforit/>
    </div>
  );
}

export default App;
